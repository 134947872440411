import { List, Skeleton, Typography } from "antd";
import React, { Fragment } from "react";
import { FiUsers } from "react-icons/fi";
import { KEYS } from "../../../Config/Keys";
import { useNavigate } from "react-router-dom";
import FadeInImage from "../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../common/Utils";
import dayjs from "dayjs";
const { Title } = Typography;

const Events = ({ loading, Events }) => {
  const navigate = useNavigate();

  return (
    <div className="list">
      <Typography style={{}}>
        <Title level={3}>Events</Title>
      </Typography>
      <List
        itemLayout="horizontal"
        className="searchedlistItemParent"
        loading={loading}
        dataSource={Events || []}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              onClick={() => {
                navigate(`/view/event/${item?._id}`, { state: "event" });
              }}
              style={{
                borderBottom: "1px solid #A3A3A3",
                cursor: "pointer",
                marginBottom: "10px"
              }}>
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  className="searchedlistItemContent"
                  style={{ padding: "20px 0" }}
                  avatar={
                    <FadeInImage
                      radiusAll
                      fallaback={fallbackprofile}
                      type={"Fadesearchedprofile"}
                      src={KEYS?.API + "/" + item?.file?.file}
                    />
                  }
                  title={
                    <div className="SearchItemh4">
                      {item?.name?.last}
                      <div className="ListItemInfo searchListItemInfo">
                        <span>
                          {item?.startDate ? dayjs(item?.startDate)?.format("DD MMMM YYYY") : ""}
                          {"  -  "}
                          {item?.endDate ? dayjs(item?.endDate)?.format("DD MMMM YYYY") : ""}
                        </span>
                        <span>
                          <FiUsers />
                          {item?.registerteams?.length + " Teams"}
                        </span>
                      </div>
                    </div>
                  }
                  description={
                    <Fragment>
                      <h4 style={{ margin: "0" }}>{item?.name}</h4>
                      {item?.description?.length > 200
                        ? item?.description?.slice(0, 200) + "..."
                        : item?.description}
                    </Fragment>
                  }
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default Events;
