import React from "react";
import { Collapse } from "antd";
import { IoFilterSharp } from "react-icons/io5";
import { Typography, DatePicker, Radio, Space, Select } from "antd";
import moment from "moment";
const { Panel } = Collapse;
const { Title } = Typography;

const FilterBarAccrodian = ({ filter, handleFilterChange }) => {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["FilterAccordiant"]}
      expandIcon={({ isActive }) => (
        <IoFilterSharp
          style={{ fontSize: "1.3rem", marginTop: ".19rem" }}
          rotate={isActive ? 90 : 0}
        />
      )}
      style={{
        border: " 1px solid #e1e1e1",
        width: "100% !important",
        minWidth: "100%",
        marginBottom: "0"
      }}
      className="sideItem">
      <Panel
        header={
          <Title style={{ margin: "0" }} level={4}>
            {" "}
            Filters{" "}
          </Title>
        }
        key="1"
        className={`site-collapse-custom-panel`}>
        <div className="FilterBar" style={{ border: "none" }}>
          <Space size={"large"} direction="vertical">
            {/* Date */}
            <Space direction="vertical">
              <Title level={5} underline>
                Date
              </Title>
              <DatePicker
                className="FilterBarInputs"
                value={filter?.date === "" ? undefined : moment(filter?.date)}
                onChange={(_, dateString) => {
                  handleFilterChange("date", dateString);
                }}
              />
            </Space>
            {/* Locaiotn */}
            <Space direction="vertical">
              <Title level={5} underline>
                Location
              </Title>
              <Select
                className="FilterBarInputs"
                placeholder="Islamabad"
                onChange={(e) => handleFilterChange("location", e)}
                value={filter?.location === "" ? undefined : filter?.location}
                allowClear>
                <Select.Option value="islamabad">Islamabad</Select.Option>
                <Select.Option value="peshawar">Peshawar</Select.Option>
                <Select.Option value="lahore">Lahore</Select.Option>
                <Select.Option value="multan">Multan</Select.Option>
              </Select>
            </Space>
            {/* Status */}

            {/* Type */}
            <Space direction="vertical">
              <Title level={5} underline>
                Type
              </Title>
              <Space direction="vertical">
                <Title level={5} underline>
                  Type
                </Title>
                <Radio.Group
                  value={filter?.type}
                  onChange={(e) => {
                    handleFilterChange("type", e.target.value);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                  <Radio value={"physical"}>Physical Sports</Radio>
                  <Radio value={"esports"}>E-Sports</Radio>
                </Radio.Group>
              </Space>
            </Space>
            {/* Name of Sports */}
            <Space direction="vertical">
              <Title level={5} underline>
                Name of Sports
              </Title>
              <Select
                className="FilterBarInputs"
                placeholder={"Cricket"}
                value={filter?.nameOfSports === "" ? undefined : filter?.nameOfSports}
                onChange={(e) => handleFilterChange("nameOfSports", e)}
                allowClear>
                <Select.Option value="cricket">cricket</Select.Option>
                <Select.Option value="football">football</Select.Option>
                <Select.Option value="hockey">hockey</Select.Option>
              </Select>
            </Space>
          </Space>
        </div>
      </Panel>
    </Collapse>
  );
};

export default FilterBarAccrodian;
