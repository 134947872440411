import { List, Skeleton, Typography } from "antd";
import React, { Fragment } from "react";
import { FiUsers } from "react-icons/fi";
import { KEYS } from "../../../Config/Keys";
import { getDayandMonth } from "../Functions";
import { useNavigate } from "react-router-dom";
import { fallbackprofile, getactivefollowfollowers } from "../../../common/Utils";
import FadeInImage from "../../../common/Images/FadeInImage";
const { Title } = Typography;

const Players = ({ loading, Players }) => {
  const navigate = useNavigate();

  return (
    <div className="list">
      <Typography style={{}}>
        <Title level={3}>Players</Title>
      </Typography>
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={Players || []}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              onClick={() => {
                navigate(`/view/player/${item?._id}/timeline`, { state: "player" });
              }}
              style={{
                borderBottom: "1px solid #A3A3A3",
                cursor: "pointer",
                marginBottom: "10px"
              }}>
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  className="searchedlistItemContent"
                  style={{ padding: "20px 0" }}
                  avatar={
                    <FadeInImage
                      radiusAll
                      fallaback={fallbackprofile}
                      type={"Fadesearchedprofile"}
                      src={KEYS?.API + "/" + item?.profilepic}
                    />
                  }
                  title={
                    <div className="SearchItemh4">
                      {item?.name?.last}
                      <div className="ListItemInfo searchListItemInfo">
                        <span>{getDayandMonth(item?.createdAt)}</span>
                        <div className="searchPOItem">
                          <span>
                            <FiUsers />
                            {getactivefollowfollowers(item?.followers)?.length + " Followers"}
                          </span>
                          <span>
                            <FiUsers />
                            {getactivefollowfollowers(item?.followings)?.length + " Following"}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  description={
                    <Fragment>
                      <h4 style={{ margin: "0" }}>
                        {item?.name}
                        {item?.username && (
                          <span style={{ fontSize: "12px", color: "gray" }}>
                            ( {item?.username} )
                          </span>
                        )}
                      </h4>
                      {item?.description?.length > 200
                        ? item?.description?.slice(0, 200) + "..."
                        : item?.description}
                    </Fragment>
                  }
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default Players;
