import { GetAllSearchResults } from "../../../Config/API/search";
import { error } from "../../../common/Alerts/Message";
import moment from "moment";

/**
 * @deprecated The implementation was moved to the SearchPage itself
 * @param {*} text
 * @param {*} setAllSearch
 * @param {*} setLoading
 * @param {*} searchFor
 */
export const GetAll = async (text, setAllSearch, setLoading, searchFor) => {
  try {
    if (text !== "" || text !== null) {
      setLoading(true);
      const response = await GetAllSearchResults({ text, searchFor });
      const data = await response.json();
      if (data?.type === "failure") {
        error(data.result);
      } else if (data?.type === "success") {
        setAllSearch(data?.searchData);
        // if (data?.EventData?.length !== 0) {
        //   setAllSearch?.setEvents(data?.searchData);
        // }
        // if (data?.PanelData?.length !== 0) {
        //   setAllSearch?.setPanels(data?.PanelData);
        // }
        // if (data?.PlayerData?.length !== 0) {
        //   setAllSearch?.setPlayers(data?.PlayerData);
        // }
        // if (data?.TeamData?.length !== 0) {
        //   setAllSearch?.setTeams(data?.TeamData);
        // }
        // if (data?.organizerData?.length !== 0) {
        //   setAllSearch?.setOrganizers(data?.organizerData);
        // }
      }
      setLoading(false);
    }
  } catch (e) {
    setLoading(false);
    error(e.message);
  }
};

export const getDayandMonth = (time) => {
  var check = moment(time, "YYYY/MM/DD");
  var day = check.format("D");
  var m = check.format("MM");
  m = parseInt(m);

  if (m === 1) m = "January";
  else if (m === 2) m = "Feburary";
  else if (m === 3) m = "March";
  else if (m === 4) m = "April";
  else if (m === 5) m = "May";
  else if (m === 6) m = "June";
  else if (m === 7) m = "July";
  else if (m === 8) m = "August";
  else if (m === 9) m = "September";
  else if (m === 10) m = "October";
  else if (m === 11) m = "November";
  else if (m === 12) m = "December";
  return day + " " + m + "  ";
};
