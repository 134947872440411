import React from "react";
import { Button, Space } from "antd";
import { ROLES } from "../../../Config/helpers/constants";

const TopBar = ({ active, setactive, forWallet }) => (
  <Space
    className={` ${!forWallet && "SearchBarRow mt-20"}`}
    style={{ width: "100%", gap: "0 zpx" }}>
    {/* <Button
      className="buttonpo "
      onClick={() => {
        setactive("all");
      }}
      type={active === "all" && "primary"}>
      All
    </Button> */}
    {!forWallet && (
      <Button
        className="buttonpo"
        onClick={() => {
          setactive("event");
        }}
        type={active === "event" && "primary"}>
        Events
      </Button>
    )}
    <Button
      className="buttonpo"
      onClick={() => {
        setactive(ROLES.player);
      }}
      type={active === ROLES.player && "primary"}>
      Players
    </Button>
    <Button
      className="buttonpo"
      onClick={() => {
        setactive(ROLES.organizer);
      }}
      type={active === ROLES.organizer && "primary"}>
      Organizers
    </Button>
    <Button
      className="buttonpo"
      onClick={() => {
        setactive(ROLES.panel);
      }}
      type={active === ROLES.panel && "primary"}>
      Panels
    </Button>
    <Button
      className="buttonpo"
      onClick={() => {
        setactive(ROLES.team);
      }}
      type={active === ROLES.team && "primary"}>
      Teams
    </Button>
  </Space>
);

export default TopBar;
