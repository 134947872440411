import { Row, Col, Pagination } from "antd";
import React, { useState, useEffect } from "react";
import FilterBar from "./FilterBar/index";
import Main from "./Main";
import TopBar from "./TopBar/TopBar";
// import { GetAll } from "./Functions";
import { useLocation, useParams } from "react-router-dom";
import { ROLES } from "../../Config/helpers/constants";
import { error } from "../../common/Alerts/Message";
import { GetAllSearchResults } from "../../Config/API/search";
const SearchPage = () => {
  const { state } = useLocation();

  const [active, setactive] = useState(ROLES.event);
  const [allSearchData, setAllSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filter
  const [filter, setFilter] = useState({ type: "", nameOfSports: "", location: "", date: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 25;

  const getAll = async (page) => {
    try {
      if (query !== "" || query !== null) {
        setLoading(true);
        const newOffset = (page - 1) * limit;

        const response = await GetAllSearchResults(
          { text: query, searchFor: active, ...filter },
          newOffset,
          limit
        );
        const data = await response.json();
        if (data?.type === "failure") {
          error(data.result);
        } else if (data?.type === "success") {
          setAllSearchData(data?.searchData);
          setTotalPages(Math.ceil(data?.totalCount / limit));
          setCurrentPage(page);
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      error(e.message);
    }
  };

  const { query } = useParams();
  useEffect(() => {
    // GetAll(query, setAllSearchData, setLoading, active);
    getAll(1);
    return () => {
      setLoading(null);
      setAllSearchData([]);
    };
  }, [query, active, filter]);

  useEffect(() => {
    if (state === "teams") setactive(ROLES.team);
    if (state === "panels") setactive(ROLES.panel);
  }, [state]);

  const goToPage = (pageNumber) => {
    getAll(pageNumber);
  };

  return (
    <div style={{ background: "#F5F7F9", minHeight: "100vh" }}>
      <TopBar active={active} setactive={setactive} />
      <Row justify="space-between" className="SearchPage">
        <Col style={{}} xs={24} sm={24} md={24} lg={6}>
          <FilterBar filter={filter} setFilter={setFilter} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={17}>
          <Main
            allSearchData={allSearchData}
            active={active}
            loading={loading}
            setactive={setactive}
            filter={filter}
          />
          <Pagination
            current={currentPage}
            total={totalPages * limit}
            pageSize={limit}
            showSizeChanger={false}
            onChange={goToPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SearchPage;
