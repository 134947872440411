import { Space } from "antd";
import EventsComponent from "./Events";
import PlayerComponent from "./Player";
import OrganizersComponent from "./Organizers";
import TeamsComponent from "./Teams";
import PanelsComponent from "./Panels";
import React, { Fragment } from "react";
import Loader from "../../../common/cards/Loader";
// import NoData from "../../../common/cards/noData";
import { ROLES } from "../../../Config/helpers/constants";
function SearchPageMain({ active, loading, allSearchData, setactive }) {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {loading ? <Loader /> : null}
      {/* {active.all === "primary" && !loading ? (
        <Fragment>
          {Events?.length > 0 ? (
            <EventsComponent
              Events={Events}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Players?.length > 0 ? (
            <PlayerComponent
              loading={loading}
              Players={Players}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Organizers?.length > 0 ? (
            <OrganizersComponent
              loading={loading}
              Organizers={Organizers}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Panels?.length > 0 ? (
            <PanelsComponent
              Panels={Panels}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Teams?.length > 0 ? (
            <TeamsComponent
              Teams={Teams}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {!Events && !Players && !Organizers && !Teams && !Panels && !loading ? (
            <NoData noText />
          ) : null}
        </Fragment>
      ) : null} */}
      {active === ROLES.event && !loading ? (
        <Fragment>
          <EventsComponent setactive={setactive} Events={allSearchData} loading={loading} />
        </Fragment>
      ) : null}
      {active === ROLES.player && !loading ? (
        <Fragment>
          <PlayerComponent setactive={setactive} Players={allSearchData} loading={loading} />
        </Fragment>
      ) : null}
      {active === ROLES.organizer && !loading ? (
        <Fragment>
          <OrganizersComponent setactive={setactive} Organizers={allSearchData} loading={loading} />
        </Fragment>
      ) : null}
      {active === ROLES.team && !loading ? (
        <Fragment>
          <TeamsComponent setactive={setactive} Teams={allSearchData} loading={loading} />
        </Fragment>
      ) : null}
      {active === ROLES.panel && !loading ? (
        <Fragment>
          <PanelsComponent setactive={setactive} Panels={allSearchData} loading={loading} />
        </Fragment>
      ) : null}
    </Space>
  );
}

export default SearchPageMain;
