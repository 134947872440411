import { Avatar, List, Skeleton, Typography } from "antd";
import React, { Fragment } from "react";
import { FiUsers } from "react-icons/fi";
import { KEYS } from "../../../Config/Keys";
import { getDayandMonth } from "../Functions";
import { useNavigate } from "react-router-dom";
import { getactivemembers } from "../../../common/Utils";
const { Title } = Typography;

const Teams = ({ loading, Teams }) => {
  const navigate = useNavigate();

  return (
    <div className="list">
      <Typography style={{}}>
        <Title level={3}>Teams</Title>
      </Typography>
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={Teams || []}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              onClick={() => {
                navigate(`/view/team/${item?._id}/timeline`, { state: "team" });
              }}
              style={{
                borderBottom: "1px solid #A3A3A3",
                cursor: "pointer",
                marginBottom: "10px"
              }}>
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  className="searchedlistItemContent"
                  style={{ padding: "20px 0" }}
                  avatar={
                    <Avatar
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 8
                      }}
                      src={KEYS?.API + "/" + item?.profilepic}
                    />
                  }
                  title={
                    <div className="SearchItemh4">
                      {item?.name?.last}
                      <div className="ListItemInfo searchListItemInfo">
                        <span>{getDayandMonth(item?.establish)}</span>
                        <span>
                          <FiUsers />
                          {getactivemembers(item?.members)?.length + " Members"}
                        </span>
                      </div>
                    </div>
                  }
                  description={
                    <Fragment>
                      <h4 style={{ margin: "0" }}>
                        {item?.name}
                        {item?.username && (
                          <span style={{ fontSize: "12px", color: "gray" }}>
                            ( {item?.username} )
                          </span>
                        )}
                      </h4>
                      {item?.description?.length > 200
                        ? item?.description?.slice(0, 200) + "..."
                        : item?.description}
                    </Fragment>
                  }
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default Teams;
