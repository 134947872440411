import { KEYS } from "../Keys";

export const PlayerUploadProfileImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/profileimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const PlayerUploadCoverImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/profilecoverimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const EditProfileImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/updateprofileimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const EditCoverImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/updateprofilecoverimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const PlayerGetProfileAPI = async () => {
  const response = await fetch(KEYS.API + "/player/getprofile", {
    // signal: controller.signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const PlayerGetFollowersAPI = async () => {
  const response = await fetch(KEYS.API + "/player/getfollowers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const CreateProfilePlayerAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/profilecreate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
export const EditProfilePlayerAPI = async (values) => {
  const response = await fetch(KEYS.API + "/player/profileupdate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetPlayerPosts = async (values) => {
  const response = await fetch(KEYS.API + "/player/getplayerposts", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: values })
  });
  return response;
};

export const GetAllPlayers = async () => {
  const response = await fetch(KEYS.API + "/player/getallplayers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const DeletePlayerPost = async (values) => {
  const response = await fetch(KEYS.API + "/player/deleteplayerpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const EditPlayerPost = async (values) => {
  const response = await fetch(KEYS.API + "/post/updatepost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetSinglePlayer = async (values) => {
  const response = await fetch(KEYS.API + "/player/getsingleplayer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: typeof values === "string" ? JSON.stringify({ id: values }) : JSON.stringify(values)
  });
  return response;
};
export const PlayerAcceptFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/player/acceptfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PlayerRejectFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/player/cancelfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PlayerDeleteFollowRequest = async (values) => {
  const response = await fetch(KEYS.API + "/player/deletefollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PlayerDeleteFollower = async (values) => {
  const response = await fetch(KEYS.API + "/player/deletefollowerplayer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetPlayerEventHistory = async (id, setData) => {
  try {
    const payload = {
      playerId: id
    };
    const response = await fetch(KEYS.API + "/player/getplayereventshistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: JSON.stringify(payload)
    });
    const result = await response.json();

    if (result?.type === "success") {
      setData(result?.result || []);
    }
  } catch (error) {
    return await error;
  }
};
