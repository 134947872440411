import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Button, Tabs, Grid, Space, Typography, Rate } from "antd";
const { Text } = Typography;
import { Link, useNavigate, useParams } from "react-router-dom";

import { KEYS } from "../../../Config/Keys";
import {
  followFunction,
  unFollowProfile,
  getactivefollowfollowers,
  checkIsAccepted,
  checkjoinedstatus,
  fallbackprofile
} from "../../../common/Utils";
import Loader from "../../../common/cards/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { PROFILE, setProfile } from "../../../Store/Features/ProfileSlice";
import Notification from "../../../common/Notification/TeamPanelNotifications";
import FadeInImage from "../../../common/Images/FadeInImage";
import { useGroupRequests } from "../../../common/hooks/useGroupRequests";
import useVariables from "../../../common/hooks/useVariables";
import ProfileStatuSwtich from "./profileStatuSwtich";
import { ROLES } from "../../../Config/helpers/constants";
import ViewRatings from "../../../common/Model/ViewRatings";

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

export default function OrganizerPNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [isFollowing, setisFollowing] = useState(false);
  const [joined, setisjoined] = useState(false);
  const [accepted, setaccpted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const breakpoints = useBreakpoint();

  const { id } = useParams();
  const { viewer, panelid } = useVariables();
  const pid = viewer?.id;
  const flag = viewer?.flag;

  const { url, other } = useCurrentPage();

  // Group id
  const fetchid = other ? id : panelid;
  //fetching profile
  const { profileLoding } = useFetchProfile(fetchid, "panel", setisFollowing);
  //Group Profile
  let panel = useSelector(PROFILE);
  //join request api
  const { PanelJoinRequestFun } = useGroupRequests();

  // check if the viewer is was added by panel to join this panel
  const isRequestedByPanelToJoin = panel?.members
    ?.filter((member) => !member?.active && member?.type?.toLowerCase() === "add")
    ?.find((current) => current?.organizer?._id === pid);

  const followers = getactivefollowfollowers(panel?.followers);

  useEffect(() => {
    checkIsAccepted(setaccpted, followers, viewer);
  }, [followers?.length, isFollowing]);

  useEffect(() => {
    checkjoinedstatus(panel, pid, setisjoined);
  }, [panel, pid]);
  // Follow Following Buttons
  const FollowFollowingButton = () => {
    return (
      <Fragment>
        <Row justify="center">
          <p>
            <Button
              onClick={() =>
                navigate(
                  `/${
                    viewer?.flag === "player"
                      ? "player"
                      : viewer?.flag === "sponsor"
                      ? "sponsor"
                      : "organizer"
                  }/messages/chat`,
                  {
                    state: {
                      id: id,
                      role: "panel",
                      profilepic: panel.profile,
                      name: panel.name
                    }
                  }
                )
              }
              className="profilebuttons blueButton btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white",
                marginRight: "5px"
              }}>
              Chat
            </Button>
          </p>
          {flag === "organizer" && pid !== panel?.profileId ? (
            <p>
              <Button
                loading={loading === "join" && true}
                onClick={() => {
                  PanelJoinRequestFun(setloading, setisjoined);
                }}
                className="profilebuttons blueButton btnMobileDimensions"
                style={{
                  height: "auto",
                  padding: "6px 10px",
                  margin: "0",
                  color: "white",
                  marginRight: "10px"
                }}>
                {
                  // joined === "joined" ? "Leave Team" :
                  //   joined === "requested" ? "Cancel Join Request" : "Join Panel"
                  joined === "joined"
                    ? "Joined"
                    : joined === "requested"
                    ? "Join Requested"
                    : isRequestedByPanelToJoin
                    ? "Accept Join Request"
                    : "Join Panel"
                }
              </Button>
            </p>
          ) : null}
          <p>
            {isFollowing ? (
              <Button
                loading={loading === "unfollow" && true}
                onClick={() => {
                  unFollowProfile(
                    panel,
                    null,
                    "panel",
                    setloading,
                    setisFollowing,
                    "otherprofile",
                    setProfile,
                    dispatch,
                    setaccpted
                  );
                }}
                className="profilebuttons blueButton btnMobileDimensions"
                style={{
                  height: "auto",
                  padding: "6px 10px",
                  margin: "0",
                  color: "white"
                }}>
                {accepted ? "UnFollow" : "Cancel Follow Request"}
              </Button>
            ) : (
              <Button
                loading={loading === "follow" && true}
                onClick={async () =>
                  followFunction(
                    id,
                    setloading,
                    setisFollowing,
                    null,
                    setProfile,
                    dispatch,
                    setaccpted
                  )
                }
                className="addtocalendar profilebuttons btnMobileDimensions"
                style={{
                  height: "auto",
                  padding: "6px 10px",
                  margin: "0",
                  color: "white"
                }}>
                Follow
              </Button>
            )}
          </p>
        </Row>
      </Fragment>
    );
  };
  //Edit Button
  const EditButton = () => {
    return (
      <Space>
        <Button>
          <Link to={`/organizer/createevent`}>Create Event</Link>
        </Button>
        <p style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <ProfileStatuSwtich />
          <Button
            onClick={() => navigate(`/organizer/editpanel/${panel?._id}`, { state: "panel" })}
            className="addtocalendar profilebuttons btnMobileDimensions"
            style={{
              height: "auto",
              padding: "6px 10px",
              margin: "0",
              color: "white"
            }}>
            Edit Profile
          </Button>
        </p>
      </Space>
    );
  };

  const isAdmin = panel?.profileId?._id || panel?.profileId === pid;
  return (
    <Fragment>
      {(loading !== "follow" && loading !== "unfollow" && loading !== "join" && loading) ||
      profileLoding ? (
        <Loader fullpage loading="white" />
      ) : null}
      <Row>
        <Col span={24} className="toheader">
          <FadeInImage
            preview
            type={"Fadecover"}
            className="tocover"
            src={KEYS.API + "/" + panel?.cover}
          />
          <FollowersFollowingDataComp
            url={url}
            other={other}
            profile={panel}
            followers={followers}
            id={id}
          />
        </Col>
      </Row>
      <Row
        wrap="wrap"
        align="top"
        justify="space-between"
        className="tosubnav tosubnav1 teampanelnav">
        <Col className="tosubnavcenter" xl={3} lg={3} md={4} sm={6} xs={24}>
          {/* <Image
            wrapperClassName="abs"
            alt=""
            className="toprofilepic3"
            src={KEYS.API + "/" + panel?.profile}
            fallback={fallbackcover}

          /> */}
          <div className="abs">
            <FadeInImage
              preview
              type="Fadeprofile"
              fallaback={fallbackprofile}
              src={KEYS.API + "/" + panel?.profile}
            />
          </div>
          <Space direction="vertical" size="small">
            <Text
              className="toname"
              style={{
                textAlign: "center",
                margin: "0px 0 0 0",
                minWidth: "170px",
                fontSize: 20
              }}>
              {panel?.name || "-"}{" "}
            </Text>{" "}
            <Text
              className="toname"
              style={{
                textAlign: "center",
                margin: "0px 0 0 0",
                minWidth: "170px",
                fontSize: 12
              }}>
              {panel?.username && <span style={{ color: "gray" }}>( {panel?.username} )</span>}
            </Text>{" "}
          </Space>
        </Col>
        {!breakpoints.md && (
          <Col className="tosubnavcenter" sm={6} xs={24}>
            <div className="timelinedesc">
              <Rate allowHalf disabled value={panel?.rating?.averageRating} />
              <ViewRatings type={ROLES.panel} id={panel?._id} />
            </div>
          </Col>
        )}
        <SubLinksWrapper
          FollowFollowingButton={FollowFollowingButton}
          EditButton={EditButton}
          setIsOpen={setIsOpen}
          profile={panel}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          flag={flag}
          url={url}
          id={id}
        />
      </Row>

      <XsButtonsRow
        other={other}
        EditButton={EditButton}
        FollowFollowingButton={FollowFollowingButton}
        isAdmin={isAdmin}
      />
    </Fragment>
  );
}

const FollowersFollowingDataComp = ({ url, other, profile, followers, id }) => {
  return (
    <div className="tofollower">
      <div className={url === "followers" ? "totitle totitleBlue" : "totitle"}>
        {other ? (
          <Link to={`/view/panel/${profile?._id || id}/followers`}>
            <p className="toFollow"> Followers</p>
            <p className="tocount"> {followers?.length}</p>
          </Link>
        ) : (
          <Link to={`/organizer/panel/${profile?._id || id}/followers`}>
            <p className="toFollow"> Followers</p>
            <p className="tocount"> {followers?.length}</p>
          </Link>
        )}
      </div>
    </div>
  );
};
const XsButtonsRow = ({ other, EditButton, isAdmin, FollowFollowingButton }) => {
  return (
    <Row className="tosubnav d-none teampanelnav d-md-block">
      <Col span={24}>
        {isAdmin && !other ? (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <EditButton />
          </Col>
        ) : (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <FollowFollowingButton />
          </Col>
        )}
      </Col>
    </Row>
  );
};
const SubLinksWrapper = ({
  profile,
  other,
  url,
  setIsOpen,
  id,
  isOpen,
  isAdmin,
  EditButton,
  FollowFollowingButton,
  flag
}) => {
  const screens = useBreakpoint();
  return (
    <Fragment>
      {screens["sm"] ? (
        <SubLinksDesktop
          FollowFollowingButton={FollowFollowingButton}
          EditButton={EditButton}
          setIsOpen={setIsOpen}
          profile={profile}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          flag={flag}
          url={url}
          id={id}
        />
      ) : (
        <SubLinksTabs
          setIsOpen={setIsOpen}
          profile={profile}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          url={url}
          id={id}
        />
      )}
    </Fragment>
  );
};
const SubLinksDesktop = ({
  profile,
  other,
  url,
  setIsOpen,
  id,
  isOpen,
  isAdmin,
  EditButton,
  FollowFollowingButton
}) => {
  return (
    <Col xl={20} lg={19} md={17} sm={15} xs={24}>
      <Row className="subnavLinks subnavLinks2">
        <Col className="tosubnavcenter">
          <Link
            to={
              !other
                ? `/organizer/panel/${profile?._id || id}/timeline`
                : `/view/panel/${profile?._id}/timeline`
            }
            state={"panel"}>
            <p className={url === "timeline" ? "toactive" : null}>Timeline</p>
          </Link>
        </Col>

        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/panel/${profile?._id || id}/media`
                : `/organizer/panel/${profile?._id || id}/media`
            }>
            <p className={url === "media" ? "toactive" : null}>Media</p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/panel/${profile?._id || id}/members`
                : `/organizer/panel/${profile?._id || id}/members`
            }>
            <p className={url === "members" ? "toactive" : null}>Members</p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/panel/${profile?._id || id}/sponsors`
                : `/organizer/panel/${profile?._id || id}/sponsors`
            }>
            <p className={url === "sponsors" ? "toactive" : null}>Sponsors</p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          {isAdmin && !other && (
            <Link to={`/organizer/panel/${profile?._id || id}/chat`}>
              <p className={url === "chat" ? "toactive" : null}>Chat</p>
            </Link>
          )}
        </Col>
        {isAdmin && !other && (
          <Col className="tosubnavcenter">
            {isAdmin && !other && (
              <Link to={`/organizer/panel/${profile?._id || id}/wallet`}>
                <p className={url === "wallet" ? "toactive" : null}>Wallet</p>
              </Link>
            )}
          </Col>
        )}
        {isAdmin && !other ? (
          <Col className="tosubnavcenter">
            <p style={{ cursor: "pointer" }} className="notificationsnavbutton">
              <span
                onClick={(e) => {
                  e.currentTarget.classList.toggle("toactive");
                  setIsOpen(!isOpen);
                }}>
                Notifications
              </span>
              {isOpen ? <Notification open={isOpen} setOpen={setIsOpen} /> : null}
            </p>
          </Col>
        ) : null}
        <Fragment>
          {isAdmin && !other ? (
            <Col style={{ marginLeft: "auto" }} className="tosubnavcenter teampanelnav d-md-none">
              <EditButton />
            </Col>
          ) : (
            <Col
              style={{
                marginLeft: "auto",
                minWidth: "300px",
                justifyContent: "flex-end"
              }}
              className="tosubnavcenter teampanelnav d-md-none">
              <FollowFollowingButton />
            </Col>
          )}
        </Fragment>
      </Row>
    </Col>
  );
};
const SubLinksTabs = ({ setIsOpen, profile, isAdmin, isOpen, other, url, id }) => {
  const arr = [
    {
      label: "timeline",
      url: other
        ? `/view/panel/${profile?._id || id}/timeline`
        : `/organizer/panel/${profile?._id || id}/timeline`,
      key: "timeline"
    },
    {
      label: "media",
      url: other
        ? `/view/panel/${profile?._id || id}/media`
        : `/organizer/panel/${profile?._id || id}/media`,
      key: "media"
    },
    {
      label: "members",
      url: other
        ? `/view/panel/${profile?._id || id}/members`
        : `/organizer/panel/${profile?._id || id}/members`,
      key: "members"
    },
    {
      label: "sponsors",
      url: other
        ? `/view/panel/${profile?._id || id}/sponsors`
        : `/organizer/panel/${profile?._id || id}/sponsors`,
      key: "sponsors"
    },
    !other && {
      label: "chat",
      url: isAdmin && !other ? `/organizer/panel/${profile?._id || id}/chat` : null,
      key: "chat"
    },
    !other &&
      isAdmin && {
        label: (
          <p style={{ cursor: "pointer" }} className="notificationsnavbutton">
            <span
              onClick={(e) => {
                e.currentTarget.classList.toggle("toactive");
                setIsOpen(!isOpen);
              }}>
              Notifications
            </span>
            {isOpen !== false ? <Notification open={isOpen} setOpen={setIsOpen} /> : null}
          </p>
        ),
        key: "notifications"
      }
  ];

  if (isAdmin && !other) {
    arr.push({
      label: "wallet",
      url: isAdmin && !other ? `/organizer/panel/${profile?._id || id}/wallet` : null,
      key: "wallet"
    });
  }

  const navigate = useNavigate();

  return (
    <Tabs
      tabPosition={"top"}
      centered
      activeKey={url}
      size="small"
      onChange={(e) => {
        if (!String(e).includes("notifications")) navigate(e);
      }}
      className="MobileSubNavTabLinks">
      {arr.map(({ label, key }) => (
        <TabPane tab={label} key={`${key}`} />
      ))}
    </Tabs>
  );
};
