import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import OrganizerPLayout from "../../layout/panelLayout/OrganizerPLayout";
import PanelMembers from "./PanelMembers/PanelMembers";
import Timeline from "./Timeline";
import Home from "./Home";
import Media from "../../../common/Media";
import Follow from "../../../common/Follow";

import CommingSoon from "../../../common/cards/ComingSoon/CommingSoon";
import "../../../styles/organizer/panelorganizer.css";
import Events from "../Events";
import About from "./About";

import NotificationMobileTP from "../../../common/Notification/TeamPanelNotifications/NotificatinonMobile";
import ChatRoom from "../../ChatRoom";
import UserWallet from "../../Wallet/UserWallet";
import { ROLES } from "../../../Config/helpers/constants";

const Panel = () => {
  const { id } = useParams();
  return (
    <Routes>
      <Route path="/" element={<OrganizerPLayout />}>
        <Route index path="/feed" element={<Home />} />
        <Route path="events/*" element={<Events />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/members" element={<PanelMembers />} />
        <Route path="/media" element={<Media />} />

        {/* TPId : is using for both Teams and panels, each panel and teams uses a id to work */}
        <Route path="/followers" element={<Follow />} />
        <Route path="/followings" element={<Follow />} />
        <Route path="/about" element={<About />} />
        <Route path="/sponsors" element={<CommingSoon />} />
        <Route path={`/chat`} element={<ChatRoom />} />
        <Route path="/wallet" element={<UserWallet userType={ROLES.panel} userId={id} />} />

        <Route
          path="*"
          element={
            <h1
              style={{
                textAlign: "center",
                margin: "90px 0",
                fontSize: "xx-large"
              }}>
              Page Not Found
            </h1>
          }
        />
      </Route>

      <Route path="/notifications" element={<NotificationMobileTP />} />
    </Routes>
  );
};

export default Panel;
