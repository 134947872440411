import React from "react";
import { IoFilterSharp } from "react-icons/io5";
import { Typography, DatePicker, Radio, Space, Select } from "antd";
import moment from "moment";
import {
  E_SPORTS_LIST,
  LOCATIONS,
  PHYSICAL_SPORTS_LIST,
  SPORTS_TYPES,
  SPORTS_TYPES_LIST
} from "../../../Config/helpers/constants";

const { Title } = Typography;
function FilterBar({ filter, handleFilterChange }) {
  return (
    <div className="FilterBar">
      <Title level={3}>
        <IoFilterSharp
          style={{
            position: "relative",
            top: "2px",
            marginRight: "15px"
          }}
        />
        Filters
      </Title>

      <Space size={"large"} direction="vertical" style={{ marginTop: "25px" }}>
        {/* Date */}
        <Space direction="vertical">
          <Title level={5} underline>
            Date
          </Title>
          <DatePicker
            className="FilterBarInputs"
            value={filter?.date === "" || !filter?.date ? undefined : moment(filter?.date)}
            onChange={(_, dateString) => {
              handleFilterChange("date", dateString);
            }}
          />
        </Space>
        {/* Locaiotn */}
        <Space direction="vertical">
          <Title level={5} underline>
            Location
          </Title>
          <Select
            className="FilterBarInputs"
            placeholder="Islamabad"
            onChange={(e) => handleFilterChange("location", e)}
            value={filter?.location === "" ? undefined : filter?.location}
            allowClear>
            {LOCATIONS.map((location, index) => (
              <Select.Option key={index} value={location.value}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
        {/* Status */}

        {/* Type */}
        <Space direction="vertical">
          <Title level={5} underline>
            Type
          </Title>
          <Radio.Group
            value={filter?.type}
            onChange={(e) => {
              handleFilterChange("type", e.target.value);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
            {SPORTS_TYPES_LIST.map((sportsType, index) => (
              <Radio key={index} value={sportsType.value}>
                {sportsType.label}
              </Radio>
            ))}
          </Radio.Group>
        </Space>
        {/* Name of Sports */}
        <Space direction="vertical">
          <Title level={5} underline>
            Name of Sports
          </Title>
          <Select
            className="FilterBarInputs"
            placeholder={"Cricket"}
            value={filter?.nameOfSports === "" ? undefined : filter?.nameOfSports}
            onChange={(e) => {
              handleFilterChange("nameOfSports", e);
            }}
            allowClear>
            {/* render physical sports  */}
            {filter?.type === SPORTS_TYPES.physicalSports &&
              PHYSICAL_SPORTS_LIST.map((sport, index) => (
                <Select.Option key={index} value={sport.value}>
                  {sport.label}
                </Select.Option>
              ))}

            {/* render e-sports */}
            {filter?.type === SPORTS_TYPES.eSports &&
              E_SPORTS_LIST.map((sport, index) => (
                <Select.Option key={index} value={sport.value}>
                  {sport.label}
                </Select.Option>
              ))}
          </Select>
        </Space>
      </Space>
    </div>
  );
}

export default FilterBar;
