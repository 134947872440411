import React, { useEffect, useState, Fragment } from "react";
import { Button, Skeleton, List } from "antd";
import { GetAllPlayers } from "../../../Config/API/player";
import { InviteToTeam } from "../../../Config/API/teams";
import { error, success } from "../../../common/Alerts/Message";
import Swal from "sweetalert2";
import Loader from "../../../common/cards/Loader";
import { KEYS } from "../../../Config/Keys";
import { Link } from "react-router-dom";
import FadeInImage from "../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../common/Utils";
import useVariables from "../../../common/hooks/useVariables";

function AllPanelMembers({ teamId, input, members }) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [name, setname] = useState("");
  const [requested, setrequested] = useState([]);

  const { viewer } = useVariables();
  // Modal
  const id = viewer?.id;
  const getAll = async () => {
    try {
      setLoading(true);
      const response = await GetAllPlayers();
      const data = await response.json();

      if (data.type === "success") {
        var filter = [];

        data?.data?.forEach((player) => {
          var found = false;
          members?.forEach((pmem) => {
            if (pmem?.player?._id === player?._id) {
              found = true;
              return;
            }
          });
          if (found === false && player?._id !== id)
            if (found === false) {
              filter.push(player);
            }
        });
        setList([...filter]);
        setFilterList([...filter]);
      }

      if (data.type === "success") {
        setLoading(false);
      } else if (data?.type === "failure") {
        error(data?.resutl);
      } else {
        error("Can't add to panel");
        console.log(data.result);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      error(e.message);
    }
  };
  // id playerId type playersentid

  const invite = async (invitingid) => {
    if (teamId) {
      try {
        setLoading(true);
        const response = await InviteToTeam({
          id: teamId,
          playerId: invitingid,
          playersentid: id,
          type: "Add"
        });
        const data = await response.json();

        if (data.type === "success") {
          success("Invitation Sent!");
          setLoading(false);
          setrequested([...requested, invitingid]);
          const members = JSON.parse(sessionStorage.getItem(`members-${teamId}`));
          sessionStorage.setItem(`members-${teamId}`, JSON.stringify([...members, invitingid]));
        } else {
          error(data.result);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      error("No User Id detected! try again later");
    }
  };

  function confirm(name, id) {
    Swal.fire({
      icon: "question",
      text: `Are you sure you want to invite ${name} `,
      showCancelButton: true,
      confirmButtonText: "Invite",
      cancelButtonColor: "var(--red)"
    }).then((e) => {
      if (e.isConfirmed) {
        setname(name);
        invite(id);
      }
    });
  }
  useEffect(() => {
    if (input === "" || !input) {
      setFilterList([...list]);
    }
    if (input && list?.length !== 0) {
      const data = list.filter((player) => {
        if (
          String(player.name).toLowerCase()?.includes(input.toLowerCase()) ||
          String(player?.role).toLowerCase()?.includes(String(input).toLowerCase())
        )
          return player;
      });
      setFilterList([...data]);
    }
  }, [input, list]);

  useEffect(() => {
    const m = JSON.parse(sessionStorage.getItem(`members-${teamId}`));
    if (!Array.isArray(m)) sessionStorage.setItem(`members-${teamId}`, JSON.stringify([]));
    else {
      setrequested(JSON.parse(sessionStorage.getItem(`members-${teamId}`)));
    }
    getAll();
    return () => {
      setList([]);
      setname(null);
    };
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loader fullpage loading="white" text={name ? `Inviting ${name} please wait` : null} />
      ) : null}
      <List
        style={{ padding: "2.5% ", overflowX: "hidden" }}
        loading={{ spinning: loading, size: "large" }}
        itemLayout={"horizontal"}
        dataSource={filterList || []}
        pagination={{
          total: filterList?.length,
          defaultCurrent: 1,
          defaultPageSize: 14,
          style: { marginTop: "50px" }
        }}
        grid={{
          gutter: [200, 30],
          column: 2,
          sm: 1,
          xs: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2
        }}
        renderItem={(item) => (
          <List.Item
            className="ListItem"
            style={{
              display: "flex",
              alignItems: "center ",
              flexWrap: "wrap",
              gap: "10px",
              minWidth: "250px !important"
            }}
            actions={[
              <Button
                className="addtocalendar"
                key="list-loadmore-edit"
                style={{
                  marginBottom: "unset",
                  minWidth: "100px",
                  height: "35px",
                  color: "white",
                  border: requested?.includes(item?._id) ? "none" : undefined,
                  background: requested?.includes(item?._id) ? "var(--grey)" : undefined,
                  cursor: requested?.includes(item?._id) ? "not-allowed" : undefined
                }}
                onClick={() => {
                  if (!requested?.includes(item?._id)) confirm(item?.name, item?._id);
                }}>
                {requested?.includes(item?._id) ? "Invited" : "Invite"}
              </Button>
            ]}>
            <Skeleton avatar title={false} loading={item?.loading} active>
              <List.Item.Meta
                style={{ alignItems: "center", gap: "10px" }}
                avatar={
                  <FadeInImage
                    src={KEYS.API + "/" + item?.profilepic}
                    fallaback={fallbackprofile}
                    type={"Fadenavprofile"}
                  />
                }
                title={
                  <Fragment>
                    <Link
                      state={"player"}
                      to={`/view/player/${item?._id}/timeline`}
                      style={{ fontWeight: "550" }}>
                      {String(item?.name).length <= 20 ? item?.name : item?.name + "..."}
                    </Link>
                    <small style={{ display: "block", marginBottom: "-5px" }}>{item?.role}</small>
                  </Fragment>
                }
              />
              {/* <div>content</div> */}
            </Skeleton>
          </List.Item>
        )}
      />
      {/* <InviteModal
                visible={visible}
                setVisible={setVisible}
                memberId={1} //member id
            /> */}
    </Fragment>
  );
}

export default AllPanelMembers;
